import React from 'react'
import styles from './post-review.module.css'
import ListGroup from 'react-bootstrap/ListGroup';
import StarRatingComponent from 'react-star-rating-component';
import Figure from 'react-bootstrap/Figure'
import Image from 'react-bootstrap/Image'
import Img from "gatsby-image"

import usericon from "./user-icon.png"
import IconVerifiedPost from "./verified-post-icon.jpg"

import { IconContext } from "react-icons";
import { FaCheckCircle } from 'react-icons/fa';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Moment from 'react-moment';
import 'moment/locale/es';

function calculateGlobalScore(reviews)
{
    if(!reviews)
        return 0;
          
    var sum = reviews.slice().map(entry => entry.rating).reduce((a, b) => a + b, 0)
    return sum / reviews.length;
}

function calculateProgressBar(reviews, ratingNumber){

    if(!reviews)
      return 0;

    var ratingNumberLength = reviews.filter(entry => entry.rating == ratingNumber).length;    
    var percentage = (ratingNumberLength / reviews.length) * 100;
    return percentage;
}

function getRatingCount(reviews, ratingNumber){
    if(!reviews)
      return 0;

    return reviews.filter(entry => entry.rating == ratingNumber).length;
}

const IndividualProgress = props => (
  <div class="row">
    <div class="col-lg-10 col-9">
      <div class="progress">
      <div class="progress-bar" role="progressbar" style={{ width: calculateProgressBar(props.reviews, props.ratingNumber) + "%" }} 
                aria-valuenow={calculateProgressBar(props.reviews, props.ratingNumber)} aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </div>
    <div class="col-lg-2 col-3"><small><strong>{getRatingCount(props.reviews, props.ratingNumber)} stars</strong></small></div>
  </div>
)

export default ({ reviews }) => (
  
  <div class="row">
    <div class="col-lg-3">
      <div id="review_summary">
        <strong>{calculateGlobalScore(reviews)}</strong>
        <em>Confiable</em>
        <small>{reviews && reviews.length} testimonios</small>
      </div>
    </div>
    <div class="col-lg-9">
      <IndividualProgress reviews={reviews} ratingNumber={5} />
      <IndividualProgress reviews={reviews} ratingNumber={4} />
      <IndividualProgress reviews={reviews} ratingNumber={3} />
      <IndividualProgress reviews={reviews} ratingNumber={2} />
      <IndividualProgress reviews={reviews} ratingNumber={1} />
    </div>
  </div>
)
