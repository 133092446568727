import React from 'react'
import styles from './post-review.module.css'
import ListGroup from 'react-bootstrap/ListGroup';
import StarRatingComponent from 'react-star-rating-component';
import Figure from 'react-bootstrap/Figure'
import Image from 'react-bootstrap/Image'
import Img from "gatsby-image"

import usericon from "./user-icon.png"
import IconVerifiedPost from "./verified-post-icon.jpg"

import { IconContext } from "react-icons";
import { FaCheckCircle } from 'react-icons/fa';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Moment from 'react-moment';
import 'moment/locale/es';

const RatingStars = props => (
  <div class="rating">      
        {props.globalRating >= 1 && <i class="icon_star voted"></i>}
        {props.globalRating < 1 && <i class="icon_star"></i>}
        {props.globalRating >= 2 && <i class="icon_star voted"></i>}
        {props.globalRating < 2 && <i class="icon_star"></i>}
        {props.globalRating >= 3 && <i class="icon_star voted"></i>}
        {props.globalRating < 3 && <i class="icon_star"></i>}
        {props.globalRating >= 4 && <i class="icon_star voted"></i>}
        {props.globalRating < 4 && <i class="icon_star"></i>}
        {props.globalRating >= 5 && <i class="icon_star voted"></i>}       
        {props.globalRating < 5 && <i class="icon_star"></i>} 
   </div>  
)

export default ({ postReview }) => (
  <div class="review-box clearfix">
  <figure class="rev-thumb"><img src={usericon} alt="" />
  </figure>
  <div class="rev-content">
    <RatingStars globalRating={postReview.rating} />    
    <div class="rev-info">
        {postReview.authorOwner.name} – <Moment locale="es" date={postReview.postDate} durationFromNow />
    </div>
    <div class="rev-text" dangerouslySetInnerHTML={{
              __html: postReview.comment.childMarkdownRemark.html,
            }}>
    </div>
  </div>
</div>  
)
