import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import CompanyRating from '../components/company-rating'
import Moment from 'react-moment';
import 'moment/locale/es';

import StarRatingComponent from 'react-star-rating-component';

import { IconContext } from "react-icons";
import { GrFacebook, GrInstagram, GrLocation  } from 'react-icons/gr';
import { AiOutlineLink, AiOutlineMail, AiOutlinePhone  } from 'react-icons/ai';
import { RiArrowRightSLine } from 'react-icons/ri';
import { FaCheckCircle } from 'react-icons/fa';

import heroStyles from '../components/hero.module.css'
import companyStyles from './company.module.css'

import PostReview from '../components/post-review'
import ReviewSummary from '../components/review-summary'
import 'bootstrap/dist/css/bootstrap.min.css';

import { getCompanyGlobalScore, getCompanyGlobalScoreLabel, getCompanyTotalReviews, getCompanyDrivingAddressLink } from '../utilities/companyHelpers'

class CompanyTemplate extends React.Component {

  formatFacebookUrlPrint(url){
    if(!url)
      return;

    return url.replace("https://www.facebook.com/", "");
  }

  formatInstagramUrlPrint(url){
    if(!url)
      return;

    return url.replace("https://www.instagram.com/", "");
  }

  formatWebsiteUrlPrint(url){
    if(!url)
      return;

    return url.replace("https://", "").replace("http://", "");
  }

  

  render() {
    const company = get(this.props, 'data.contentfulCompany')
    const siteTitle = get(this.props, 'data.siteMetaData.siteMetadata.title')

    const RatingStars = props => (
      <div class="cat_star">  
            {props.rating >= 1 && <i class="icon_star voted"></i>}            
            {props.rating >= 2 && <i class="icon_star voted"></i>}
            {props.rating >= 3 && <i class="icon_star voted"></i>}            
            {props.rating >= 4 && <i class="icon_star voted"></i>}
            {props.rating >= 5 && <i class="icon_star voted"></i>}                  
       </div>  
    )

    return (
      <Layout location={this.props.location}>
           <Helmet title={`${company.companyName} | ${siteTitle}`} /> 
<main>
    {company.heroImage &&
      <div class="hero_in" style={{ backgroundImage : `url(${company.heroImage.fixed.src})`, height : "550px" }}>
        
      </div>
      }		
		<nav class="secondary_nav sticky_horizontal_2">
			<div class="container">
				<ul class="clearfix">
					<li><a href="#description" class="active">Descripción</a></li>
					<li><a href="#reviews">Testimonios</a></li>
					<li><a href="#sidebar">Contactar</a></li>
				</ul>
			</div>
		</nav>

		<div class="container margin_60_35">
				<div class="row">
					<div class="col-lg-8">
						<section id="description">
							<div class="detail_title_1">
                <RatingStars rating={getCompanyGlobalScore(company)} />
								<h1>{company.companyName}</h1>                
								{/*
                  <a class="address" href="https://www.google.com/maps/dir//Assistance+%E2%80%93+H%C3%B4pitaux+De+Paris,+3+Avenue+Victoria,+75004+Paris,+Francia/@48.8606548,2.3348734,14z/data=!4m15!1m6!3m5!1s0x47e66e1de36f4147:0xb6615b4092e0351f!2sAssistance+Publique+-+H%C3%B4pitaux+de+Paris+(AP-HP)+-+Si%C3%A8ge!8m2!3d48.8568376!4d2.3504305!4m7!1m0!1m5!1m1!1s0x47e67031f8c20147:0xa6a9af76b1e2d899!2m2!1d2.3504327!2d48.8568361">438 Rush Green Road, Romford</a>
                */}
               
                {company.primaryAddress &&
                 <div style={{paddingTop: "10px"}}>
                          <a class="address" href={getCompanyDrivingAddressLink(company)} target="_blank">
                            {company.primaryAddress.streetAddress1} {company.primaryAddress.streetAddress2} {company.primaryAddress.city}, {company.primaryAddress.state} {company.primaryAddress.country}, {company.primaryAddress.zipCode}
                          </a>
                          </div>
                          }
                
                <div style={{paddingTop: "10px"}}>
                {company.categories && company.categories.map(category => {
                      return <span class="loc_open" style={{ marginRight : "5px" }}>{category.name}</span>
                    })}
                </div>
							</div>
              <div
                  dangerouslySetInnerHTML={{
                    __html: company.description.childMarkdownRemark.html,
                  }}
                />													
						</section>
					
					
						<section id="reviews">
							<h2>Testimonios</h2>
							<div class="reviews-container add_bottom_30">
								<ReviewSummary reviews={company.reviews} />								
							</div>
              
              <Link to={'/write-review?company=' + company.slug} class="add_top_30 add_bottom_30 btn_1">
                  Escribe un Testimonio
              </Link>
              
							<div class="reviews-container">
                {company.reviews && company.reviews.slice().sort((a, b) => Date.parse(b.postDate) - Date.parse(a.postDate)).map(entry => {
                  return (
                    <PostReview key={entry.id} postReview={entry} />                                        
                  )
                })}
							</div>
						
						</section>
					
              {/* 
						<hr/>
              
							<div class="add-review">
								<h5>Leave a Review</h5>
								<form>
									<div class="row">
										<div class="form-group col-md-6">
											<label>Name and Lastname *</label>
											<input type="text" name="name_review" id="name_review" placeholder="" class="form-control" />
										</div>
										<div class="form-group col-md-6">
											<label>Email *</label>
											<input type="email" name="email_review" id="email_review" class="form-control" />
										</div>
										<div class="form-group col-md-6">
											<label>Rating </label>
											<div class="custom-select-form">
											<select name="rating_review" id="rating_review" class="wide">
												<option value="1">1 (lowest)</option>
												<option value="2">2</option>
												<option value="3">3</option>
												<option value="4">4</option>
												<option value="5" selected>5 (medium)</option>
												<option value="6">6</option>
												<option value="7">7</option>
												<option value="8">8</option>
												<option value="9">9</option>
												<option value="10">10 (highest)</option>
											</select>
											</div>
										</div>
										<div class="form-group col-md-12">
											<label>Your Review</label>
											<textarea name="review_text" id="review_text" class="form-control" style={{ height : "130px"}}></textarea>
										</div>
										<div class="form-group col-md-12 add_top_20 add_bottom_30">
											<input type="submit" value="Submit" class="btn_1" id="submit-review" />
										</div>
									</div>
								</form>
							</div>*/}
					</div>
				
					<aside class="col-lg-4" id="sidebar">
						<div class="box_detail booking">
							<div class="price">
								<span>Contactar</span>
								<div class="score"><span><em>{company.reviews && company.reviews.length} Testimonios</em></span><strong>{getCompanyGlobalScore(company)}</strong></div>
							</div>							
													
							<a href={company.websiteUrl} target="_blank" class=" add_top_30 btn_1 full-width purchase">Visitar Sitio Web</a>
							<a href={'mailto:' + company.infoEmail}  target="_blank" class="btn_1 full-width outline wishlist"><i class="icon_mail"></i> Enviar E-mail</a>
							<div class="text-center"><small>Esta empresa esta aceptando testimonios.</small></div>
						</div>
            {/* 
						<ul class="share-buttons">
							<li><a class="fb-share" href="#0"><i class="social_facebook"></i> Share</a></li>
							<li><a class="twitter-share" href="#0"><i class="social_twitter"></i> Share</a></li>
							<li><a class="gplus-share" href="#0"><i class="social_googleplus"></i> Share</a></li>
            </ul>
            */}
					</aside>
				</div>
			
		</div>
	
	
	</main>
	

        <div className={companyStyles.mainContainer}>
         

          {/*   
          <Container fluid className={`pt-3 pb-2 ${companyStyles.titleContainer}`}>
            <Container>
              <Row>
                <Col xs={12} md={4}>
                  {company.heroImage &&
                    <Img
                      className={heroStyles.heroImage}
                      alt={company.companyName}
                      fixed={company.heroImage.fixed}                    
                    />}
                </Col>
                <Col xs={12} md={4}>
                  <h1 className={`mt-1 ${companyStyles.companyTitle}`}>{company.companyName}</h1>
                  <p className={`${companyStyles.companyTitleReviews}`}>Testimonios ({company.reviews? company.reviews.length : 0})</p>
                  
                  <CompanyRating globalRating={this.calculateGlobalScore(company)} />
                  
                  <div
                        dangerouslySetInnerHTML={{
                          __html: company.description.childMarkdownRemark.html,
                        }}
                      />
                </Col>
                <Col xs={12} md={4}>
                    <ListGroup className={`mt-3`}>
                      <ListGroup.Item action href={company.websiteUrl} target="_blank" >
                        <Container>
                            <Row noGutters={false}>
                               <Col className={'pl-0'} xs={10}>
                                <div className={companyStyles.actionLabel}>
                                  <AiOutlineLink /> {this.formatWebsiteUrlPrint(company.websiteUrl)}</div>
                                <div className={companyStyles.actionDescription}>Visitar el sitio web</div>
                               </Col>
                               <Col md="auto" xs={2}>
                                <RiArrowRightSLine /> 
                               </Col>
                            </Row>
                        </Container>
                        
                      </ListGroup.Item>
                      {company.infoEmail &&
                        <ListGroup.Item action href={'mailto:' + company.infoEmail}  target="_blank">
                          <Container>
                              <Row noGutters={false}>
                                <Col className={'pl-0'} xs={10}>
                                  <div className={companyStyles.actionLabel}><AiOutlineMail /> {company.infoEmail}</div>
                                  <div className={companyStyles.actionDescription}>Enviar un correo electrónico</div>
                                </Col>
                                <Col md="auto" xs={2}>
                                  <RiArrowRightSLine /> 
                                </Col>
                              </Row>
                          </Container>
                        </ListGroup.Item>}
                      <ListGroup.Item action>
                        <Container>
                            <Row noGutters={false}>
                               <Col className={'pl-0'}>
                                <div className={companyStyles.actionLabel}>
                                  <IconContext.Provider value={{ color: "green" }}>
                                    <FaCheckCircle />              
                                  </IconContext.Provider>
                                  &nbsp;Aceptando testimonios</div>
                                <div className={companyStyles.actionDescription}>Esta empresa esta aceptando testimonios tanto positivos como negativos.</div>
                               </Col>                               
                            </Row>
                        </Container>
                      </ListGroup.Item>                      
                    </ListGroup>             

                </Col>
              </Row>
            </Container>
          </Container>  

         
          <Container>
            <Container fluid style={{ maxWidth: 1180, margin: '0 auto' }} className={`mt-5 ${companyStyles.reviewsContainer}`}>
              <Row>
                <Col xs={12} md={8}>
                  
                  <ListGroup className={`mb-5`}>
                    <ListGroup.Item>
                      <Container>
                        <Row>
                          <Col>
                          <h2>Testimonios ({company.reviews? company.reviews.length : 0})</h2>
                          </Col>
                          <Col md="auto">
                            {company.reviews &&
                            <Link to={'/write-review?company=' + company.slug}>
                              <Button>Escribe un Testimonio</Button></Link>
                            } 
                            </Col>
                        </Row>
                      </Container>
                    </ListGroup.Item>
                    {company.reviews && company.reviews.slice().sort((a, b) => b.postDate - a.postDate).map(entry => {
                      return (
                        <PostReview key={entry.id} postReview={entry} />                                        
                      )
                    })}
                    {(!company.reviews) &&
                      <ListGroup.Item>
                      <Container>
                        <Row>
                          <Col style={{ 'text-align': 'center', 'padding' :' 20px' }}>
                          <p>No hay testimonios escritos para esta empresa. !Se el primero en escribir un testimonio!</p>
                          <Link to={'/write-review?company=' + company.slug}>
                            <Button>Escribe un Testimonio</Button>
                          </Link>
                          </Col>
                        </Row>
                      </Container>
                    </ListGroup.Item>
                    }
                  </ListGroup>
                </Col>
                <Col xs={12} md={4}>
                    <ListGroup>
                      <ListGroup.Item>
                        <h2>{company.companyName}</h2>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <p>{company.companyName} es una empresa que lleva operando desde <Moment format="YYYY/MM/DD" locale="es">{company.inceptionDate}</Moment>.</p>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: company.description.childMarkdownRemark.html,
                          }}
                        />
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <h4>Contacto</h4>
                        <ul className={companyStyles.contactList}>
                          <li><AiOutlineLink /> <a href={company.websiteUrl} target="_blank">{company.websiteUrl}</a></li>
                          {company.infoEmail &&
                            <li><AiOutlineMail /> <a href={'mailto:' + company.infoEmail}>{company.infoEmail}</a></li>}
                          {company.infoPhone &&
                            <li><AiOutlinePhone /> <a href={'tel:' + company.infoPhone}>{company.infoPhone}</a></li>}

                          {company.primaryAddress &&
                          <li>
                            <div><GrLocation /> {company.primaryAddress.streetAddress1}</div>
                            <div className={'ml-4'}>{company.primaryAddress.streetAddress2}</div>
                            <div className={'ml-4'}>{company.primaryAddress.city}, {company.primaryAddress.state}</div>
                            <div className={'ml-4'}>{company.primaryAddress.country}, {company.primaryAddress.zipCode}</div>
                          </li>}
                        </ul>
                      </ListGroup.Item>
                      {(company.socialFacebookUrl || company.socialInstagramUrl) &&
                          <ListGroup.Item>
                            <h4>Social</h4>
                            <ul className={companyStyles.socialList}>
                              {company.socialFacebookUrl &&
                                <li><GrFacebook />  <a href={company.socialFacebookUrl} target="_blank">{this.formatFacebookUrlPrint(company.socialFacebookUrl)}</a></li>}
                              {company.socialInstagramUrl &&
                                <li><GrInstagram /> <a href={company.socialInstagramUrl} target="_blank">{this.formatInstagramUrlPrint(company.socialInstagramUrl)}</a></li>}                        
                            </ul>
                          </ListGroup.Item>
                          }
                    </ListGroup>            
                </Col>
              </Row>
            </Container>   
                        </Container> */}
    
        </div>
      </Layout>
    )
  }
}

export default CompanyTemplate

export const pageQuery = graphql`
  query CompanyBySlug($slug: String!) {
    siteMetaData: site {
      siteMetadata {
        title
      }
    }
    contentfulCompany(slug: { eq: $slug }) {
      companyName
      slug
      heroImage {        
        fixed(width: 1600) {
            width
            height
            src
            srcSet
          }
      }         
      description {
        childMarkdownRemark {
          html 
        }
      },
      shortDescription
      isFeatured
      socialFacebookUrl
      socialInstagramUrl
      websiteUrl
      businessRating
      infoEmail
      infoPhone
      inceptionDate
      reviews {
        id        
        authorOwner {
          id
          name
          emailAddress
          companyName
        }
        postDate
        comment {
          childMarkdownRemark {
            html 
          }
        }
        rating
      }
      highlights {
        title
        shortDescription
        image {
          fixed(width: 400) {
            width
            height
            src
            srcSet
          }
        }         
      }
      primaryAddress {
        streetAddress1
        streetAddress2
        city
        state
        country
        zipCode
      }
      categories {
        name
        categorySlug
      }
    }
  }
`
